
import { defineComponent, ref, reactive, toRefs, nextTick } from "vue";
import selectProgram from "../siccoCheckingDocumentCollection/selectProgramCover.vue";
import { message } from "ant-design-vue";
import downloadFile from "@/utils/claim/downloadFile";
import {
  getSICCOChecingDocCollList,
  getFileReview,
  batchDownload,
  countDownload,
} from "@/API/checking/SICCOCCheckingDocColl";
import { AFCandLCApproveTableData } from "@/views/Payment/Mgmt/types";
import ImportCheckingResult from "../siccoCheckingDocumentCollection/importCheckingResult.vue";
import { ColumnProps } from "ant-design-vue/es/table/interface";
import { b64toBlob } from "@/utils/claim/b64toBlob";
import { useStore } from "vuex";
const tableWidth = window.innerWidth;

const resultOptions = reactive([
  {
    key: "1",
    value: "Approve",
  },
  {
    key: "2",
    value: "Reject",
  },
  {
    key: "3",
    value: "Pending",
  },
  {
    key: "4",
    value: "Uncheck",
  },
]);
export default defineComponent({
  components: {
    selectProgram,
    ImportCheckingResult,
  },
  setup() {
    const { state: vuexState } = useStore();
    const user = (vuexState.user as any).organization.entityCode;
    const isSICCO = user === "SICCO" ? true : false;
    const tableHeight = ref();
    const searchCon = ref();
    const queryListData = ref<any[]>([]);
    const isSelectProgramCover = ref<boolean>(false);
    const selectTableIds = ref<any[]>([]);
    const importCheckingResult = ref<any>(null);
    const supportArrayWidth = ref(0);
    //预览文件
    const base64code = ref("");
    //查看支持文档的抽屉
    const visible = ref(false);
    const type = ref("");
    const columns = ref<any>([
      // { title: "客户名称", align: 'center', dataIndex: "userName", width: 120 },
      // { title: "身份证号", align: 'center', dataIndex: "idcard", width: 150 },
      { title: "Dealer Code", dataIndex: "dealerCode", width: 120 },
      { title: "SWT Code", dataIndex: "dealerSwtCode", width: 120 },
      { title: "Dealer Name", dataIndex: "dealerName", width: 180 },
      {
        title: "Eligible Period",
        dataIndex: "eligiblePeriod",
        width: 180,
      },
      {
        title: "支持文档",
        dataIndex: "docList",
        width: 150,
        slots: { customRender: "docList" },
      },
      {
        title: "Check Result",
        dataIndex: "checkResult",
        width: 150,
        slots: { customRender: "checkResult" },
      },
      { title: "Comments", dataIndex: "comments", width: 120 },
      // { title: "Operation", dataIndex: "checkResult", width: 130, slots: { customRender: "operation" } },
    ]);
    const columns2 = reactive([
      // { title: "客户名称", align: 'center', dataIndex: "userName", width: 120 },
      // { title: "身份证号", align: 'center', dataIndex: "idcard", width: 150 },
      { title: "Dealer Code", dataIndex: "dealerCode", width: 120 },
      { title: "SWT Code", dataIndex: "dealerSwtCode", width: 120 },
      { title: "Dealer Name", dataIndex: "dealerName", width: 180 },
      {
        title: "Eligible Period",
        dataIndex: "eligiblePeriod",
        width: 180,
      },
      {
        title: "支持文档",
        dataIndex: "docList",
        width: 150,
        slots: { customRender: "docList" },
      },
      {
        title: "Check Result",
        dataIndex: "checkResult",
        width: 150,
        slots: { customRender: "checkResult" },
      },
      { title: "Comments", dataIndex: "comments", width: 120 },
      // { title: "Operation", dataIndex: "checkResult", width: 130, slots: { customRender: "operation" } },
    ]);
    //筛选条件，各个信息框的内容
    const queryListParams = reactive({
      selectProgram: "",
      dealerSwtCode: "",
      selectProgramId: "",
      selectProgramName: "",
      checkingResult: [],
    });
    nextTick(() => {
      (document.getElementsByClassName("table-box")[0] as any).style.height =
        window.innerHeight - 220 - searchCon.value.$el.scrollHeight + "px";
      const height = (document.getElementsByClassName("table-box")[0] as any)
        .style.height;
      const heightNum = parseInt(height.substr(0, height.indexOf("px")));
      // tableHeight.value = heightNum - 110;
      tableHeight.value = heightNum - 130;
    });
    const rowSelection = reactive<{
      selectedRowKeys: [];
      selectedRowData: AFCandLCApproveTableData[];
      onChange: Function;
      columnWidth: string;
      fixed: boolean;
    }>({
      onChange: (
        selectedRowKeys: [],
        selectedRows: AFCandLCApproveTableData[]
      ) => {
        rowSelection.selectedRowKeys = selectedRowKeys;
        rowSelection.selectedRowData = selectedRows;
        const selectIds = ref<any[]>([]);
        selectedRows.forEach((item, index) => {
          selectIds.value.push(item.id);
        });
        selectTableIds.value = selectIds.value;
      },
      selectedRowKeys: [],
      selectedRowData: [],
      columnWidth: "40px",
      fixed: false,
    });

    const buList = ref<any>();
    buList.value = vuexState.user.buList
      .filter((ele: any) => {
        return ele.parentId === null;
      })
      .map((ele: any) => {
        return ele.nameCn;
      });
    //分页组件
    const pagination = reactive({
      pageSize: 20,
      currentPage: 1,
      total: 0,
    });
    const doGetListData = (current: number, size: number) => {
      if (queryListParams.selectProgram) {
        const param = {
          bus: buList.value.join(","),
          programId: queryListParams.selectProgramId,
          dealerSwtCode: queryListParams.dealerSwtCode,
          checkingResult: queryListParams.checkingResult,
          current,
          size,
        };
        getSICCOChecingDocCollList(param).then((res) => {
          pagination.total = res.totalElements || 0;
          const list = res.claimCheckingDocCollectionList || [];
          if (list.length > 0) {
            rowSelection.fixed = true;
            const dataArr = ref<any[]>([]);
            const widthLst = ref<any>([]);
            const dmWidthLst = ref<any>([]);
            list.forEach((dataItem: any) => {
              const nowItem = dataItem;
              dataItem.extendInfoLst?.forEach(
                (extendItem: any, extendIndex: any) => {
                  nowItem[`content${extendIndex}`] = extendItem.content;
                }
              );
              const itemWidth = ref<any>(0);
              dataItem.docList?.forEach((docItem: any) => {
                if (docItem.docName) {
                  itemWidth.value =
                    parseInt(itemWidth.value) +
                    docItem.docName.length * 14 +
                    35;
                }
              });
              dmWidthLst.value.push(
                dataItem.dealerName ? dataItem.dealerName.length * 14 + 35 : 0
              );
              widthLst.value.push(itemWidth.value);
              dataArr.value.push(nowItem);
            });
            let largeWidth = 120;
            widthLst.value.forEach((widItem: any) => {
              if (parseInt(widItem) > largeWidth) {
                largeWidth = widItem;
              }
            });
            let dmLargeWidth = 180;
            dmWidthLst.value.forEach((widItem: any) => {
              if (parseInt(widItem) > dmLargeWidth) {
                dmLargeWidth = widItem;
              }
            });
            if (list[0].extendInfoLst && list[0].extendInfoLst.length > 0) {
              columns.value.length = 0;
              columns2.forEach((i) => {
                if (i.dataIndex === "docList") {
                  columns.value.push({
                    title: "支持文档",
                    align: "center",
                    dataIndex: "docList",
                    width: largeWidth,
                    slots: { customRender: "docList" },
                  });
                } else if (i.dataIndex === "dealerName") {
                  columns.value.push({
                    title: "Dealer Name",
                    align: "center",
                    dataIndex: "dealerName",
                    width: dmLargeWidth,
                  });
                } else {
                  columns.value.push(i);
                }
              });
              list[0].extendInfoLst.forEach((item: any, index: any) => {
                const itemWidth = item.fieldNameCn
                  ? item.fieldNameCn.length * 14 + 35
                  : 150;
                columns.value.unshift({
                  title: item.fieldNameCn,
                  align: "center",
                  dataIndex: `content${index}`,
                  width: itemWidth,
                });
              });
            }
            queryListData.value = dataArr.value;
          } else {
            queryListData.value.length = 0;
          }
        });
      } else {
        message.error("请选择program！");
      }
    };

    //更改分页组件，包括：直接跳转到指定页，上一页下一页
    const changePagination = (page: number, pagesize: number) => {
      pagination.pageSize = pagesize;
      pagination.currentPage = page;
      doGetListData(page - 1, pagesize);
    };
    //更改每页size
    const changePageSize = (page: number, pagesize: number) => {
      pagination.pageSize = pagesize;
      pagination.currentPage = page;
      doGetListData(page - 1, pagesize);
    };
    //关闭cover弹窗
    const closeSub = (param: {
      isSelectProgramCover: boolean;
      chooseData: any;
      programid: any;
      programName: any;
    }) => {
      isSelectProgramCover.value = param.isSelectProgramCover;
      if (param.chooseData !== null) {
        queryListParams.selectProgram = param.chooseData;
        queryListParams.selectProgramId = param.programid;
        queryListParams.selectProgramName = param.programName;
      }
    };
    // 打开cover弹窗
    const openCover = () => {
      // console.log('aaaaaa')
      isSelectProgramCover.value = true;
    };
    //点击支持文档
    const handlDocName = (item: any) => {
      if (item.collectionDocId.length > 0) {
        getFileReview({ id: item.collectionDocId }).then((res: any): void => {
          if (res.ext === "jpg") {
            base64code.value = "data:image/jpg;base64," + res.base64code;
            type.value = "jpg";
          } else if (res.ext === "pdf") {
            base64code.value = URL.createObjectURL(
              b64toBlob(res.base64code, "application/pdf")
            );
            type.value = "pdf";
          }

          visible.value = true;
        });
      }
    };
    //export按钮
    const handleExport = () => {
      if (selectTableIds.value.length > 0) {
        const param = {
          programCode: queryListParams.selectProgram,
          dealerSwtCode: queryListParams.dealerSwtCode,
          idList: selectTableIds.value,
        };
        const paramsConfig = {
          url: `/claimapi/claimChecking/doc/export`,
          method: "post",
          params: param,
        };
        downloadFile(paramsConfig, "application/vnd-excel; char-set=UTF-8");
      } else {
        const param = {
          checkingResult: queryListParams.checkingResult,
          dealerSwtCode: queryListParams.dealerSwtCode,
          programId: queryListParams.selectProgramId,
          programCode: queryListParams.selectProgram,
          programNameEn: queryListParams.selectProgramName,
          programNameCn: "",
        };
        const paramsConfig = {
          url: `/claimapi/claimChecking/doc/export`,
          method: "post",
          params: param,
        };
        downloadFile(paramsConfig, "application/vnd-excel; char-set=UTF-8");
      }
    };
    const showImportCheckingResult = () => {
      importCheckingResult.value.showDrawer();
    };
    //根据筛选条件查询表格
    const getTableDataByCondition = () => {
      pagination.currentPage = 1;
      doGetListData(pagination.currentPage - 1, pagination.pageSize);
    };
    const handleReset = () => {
      queryListParams.selectProgram = "";
      queryListParams.dealerSwtCode = "";
      queryListParams.selectProgramId = "";
      queryListParams.selectProgramName = "";
      queryListParams.checkingResult = [];
    };
    type Key = ColumnProps["key"];
    const state = reactive<{
      selectedRowKeys: Key[];
      loading: boolean;
    }>({
      selectedRowKeys: [], // Check here to configure the default column
      loading: false,
    });

    const onSelectChange = (selectedRowKeys: Key[]) => {
      state.selectedRowKeys = selectedRowKeys;
    };

    //关闭样例抽屉
    const closeDrawer = () => {
      visible.value = false;
    };

    const batchVisible = ref(false);
    const totalDocNum = ref(0);

    const getIdList = () => {
      let idList = [];
      if (selectTableIds.value.length > 0) {
        idList = selectTableIds.value;
      } else {
        idList = queryListData.value.map((data: any) => {
          return data.id;
        });
      }
      return idList;
    };

    const handleDownload = () => {
      // const params = {
      //   idList: getIdList(),
      //   programCode: queryListParams.selectProgram,
      //   dealerSwtCode: queryListParams.dealerSwtCode,
      //   programId: queryListParams.selectProgramId,
      //   programNameCn: "",
      //   programNameEn: queryListParams.selectProgramName,
      // };
      let params: any;
      if (selectTableIds.value.length > 0) {
        params = {
          programCode: queryListParams.selectProgram,
          dealerSwtCode: queryListParams.dealerSwtCode,
          programId: queryListParams.selectProgramId,
          // idList: selectTableIds.value,
          idList: getIdList(),
          programNameEn: queryListParams.selectProgramName,
          programNameCn: "",
        };
      } else {
        params = {
          checkingResult: queryListParams.checkingResult,
          dealerSwtCode: queryListParams.dealerSwtCode,
          programId: queryListParams.selectProgramId,
          programCode: queryListParams.selectProgram,
          programNameEn: queryListParams.selectProgramName,
          programNameCn: "",
        };
      }
      countDownload(params).then((res: any) => {
        totalDocNum.value = res;
        if (totalDocNum.value === 0) {
          message.error("Data is empty");
          return;
        }
        batchVisible.value = true;
      });
    };

    // batch download
    const batchDownloadDocuments = () => {
      let params: any;
      if (selectTableIds.value.length > 0) {
        params = {
          programCode: queryListParams.selectProgram,
          dealerSwtCode: queryListParams.dealerSwtCode,
          programId: queryListParams.selectProgramId,
          // idList: selectTableIds.value,
          idList: getIdList(),
          programNameEn: queryListParams.selectProgramName,
          programNameCn: "",
        };
      } else {
        params = {
          checkingResult: queryListParams.checkingResult,
          dealerSwtCode: queryListParams.dealerSwtCode,
          programId: queryListParams.selectProgramId,
          programCode: queryListParams.selectProgram,
          programNameEn: queryListParams.selectProgramName,
          programNameCn: "",
        };
      }
      // const params = {
      //   idList: getIdList(),
      //   programCode: queryListParams.selectProgram,
      //   dealerSwtCode: queryListParams.dealerSwtCode,
      //   programId: queryListParams.selectProgramId,
      //   programNameCn: "",
      //   programNameEn: queryListParams.selectProgramName,
      // };
      batchDownload(params).then((res: any) => {
        if (res !== null) {
          if (res.downloadUrl === null || res.downloadUrl === "") {
            message.success(
              "Download request submitted successfully. Please query download result in package task center."
            );
          }

          const downloadElement = document.createElement("a");
          downloadElement.href = res.downloadUrl;
          document.body.appendChild(downloadElement);
          downloadElement.click();
          document.body.removeChild(downloadElement);
        }
        batchVisible.value = false;
        message.success("Download request submit successful.");
      });
    };

    return {
      type,
      base64code,
      closeDrawer,
      visible,
      ...toRefs(state),
      onSelectChange,
      supportArrayWidth,
      handleReset,
      columns2,
      getTableDataByCondition,
      importCheckingResult,
      showImportCheckingResult,
      handleExport,
      handlDocName,
      resultOptions,
      openCover,
      closeSub,
      isSelectProgramCover,
      changePagination,
      changePageSize,
      pagination,
      rowSelection,
      searchCon,
      tableHeight,
      queryListData,
      queryListParams,
      tableWidth,
      columns,
      batchVisible,
      totalDocNum,
      handleDownload,
      batchDownloadDocuments,
      isSICCO,
      buList,
    };
  },
});
