import { fetchGet, fetchPost } from '../index';
import downloadFile from '@/utils/claim/downloadFile';
import axios from 'axios';

//生成SICCO Checing Deatail的列表
export const getSICCOChecingDetailList = (params = {}): Promise<any> => {
    // return fetchGet('/claimapi/dealer/doc/save', {params})
    return fetchPost('/claimapi/claimChecking/doc/searchProgram', params);
};
//查询SICCO Checking Document Collection的列表
export const getSICCOChecingDocCollList = (params = {}): Promise<any> => {
    // return fetchGet('/claimapi/dealer/doc/save', {params})
    return fetchPost('/claimapi/claimChecking/doc/search', params);
};
//导出SICCO Checking Document Collection的列表（export）
export const exportSICCOChecingDocCollList = (params = {}): Promise<any> => {
    // return fetchGet('/claimapi/dealer/doc/save', {params})
    return fetchPost('/claimapi/claimChecking/doc/export', params);
};
//支持文件预览
export const getFileReview = (params = { id: '' }): Promise<any> => {
    // return fetchGet('/claimapi/dealer/doc/save', {params})
    // return fetchGet(`/claimapi/claimChecking/doc/review?collectionDocId=${params.id}`, params)
    return fetchGet(
        `/claimapi/claimDocCollection/review?collectionDocId=${params.id}`,
        params
    );
};

//上传 Import Checking Result
export const upImportCheckingResult = <T>(format: FormData): Promise<T> => {
    // return fetchGet('/claimapi/dealer/doc/save', {params})
    return new Promise((resolve, reject) => {
        axios({
            method: 'post',
            data: format,
            url: `/claimapi/claimChecking/doc/import`,
        })
            .then((res) => {
                resolve(res.data);
            })
            .catch((error) => {
                reject(error);
            });
    });
};

export const ImportCheckingResult = <T>(
    params: any,
    format: FormData
): Promise<T> => {
    // return fetchGet('/claimapi/dealer/doc/save', {params})
    console.log(params.file);
    return new Promise((resolve, reject) => {
        axios({
            method: 'post',
            params: params,
            data: format,
            url: `/claimapi/checking/import`,
        })
            .then((res) => {
                resolve(res.data);
            })
            .catch((error) => {
                reject(error);
            });
    });
};

// [OASIS-4962] 文档下载前的文件数量统计
export const countDownload = (params: any) => {
    return fetchPost('/claimapi/claimChecking/doc/countDownload', params);
};

// 下载功能
export const batchDownload = (params: any) => {
    // downloadFile(params, 'application/vnd-excel; char-set=UTF-8');
    return fetchPost('/claimapi/claimChecking/doc/download', params);
};
