
// import axios from 'axios'
import { defineComponent, reactive, ref } from 'vue';
// import { CheckCircleOutlined } from '@ant-design/icons-vue'
// import { PayoutRoundListData } from '@/views/Claim/RT/types'
// import { message, Modal } from 'ant-design-vue';
import { message } from 'ant-design-vue';
import { docEdit } from '@/API/claim/claimDocumentSample';
import { getSICCOChecingDetailList } from '@/API/checking/SICCOCCheckingDocColl';
// import { getCoverList } from '@/API/claim/claimEnquiryProgram'
// import { ExclamationCircleOutlined } from '@ant-design/icons-vue'

const columns = reactive([
    { title: 'Program Code', dataIndex: 'programCode', width: '120px' },
    { title: 'Program Name', dataIndex: 'programName', width: '130px' },
    {
        title: 'Operation',
        dataIndex: 'checkResult',
        slots: { customRender: 'operation' },
        width: '75px',
    },
]);
interface TableType {
    username: string;
}

export default defineComponent({
    components: {},
    emits: ['close-sub-visible', 'get-table-data'],
    props: {
        isSelectProgramCover: {
            type: Boolean,
            required: true,
            default: false,
        },
        programCode: {
            type: String,
            required: false,
            default: '',
        },
        offerTypeId: {
            type: String,
            required: false,
            default: '',
        },
        offerTypeNameEn: {
            type: String,
            required: false,
            default: '',
        },
        assignee: {
            type: String,
            required: false,
            default: '',
        },
        buList: {
            type: Array,
            required: true,
            default: () => [],
        },
    },
    setup(props, context) {
        // const docId = (props as any).docId;
        // const programCode = (props as any).programCode;
        // const offerTypeId = (props as any).offerTypeId;
        // const offerTypeNameEn = (props as any).offerTypeNameEn;
        const assignees = (props as any).assignee;
        const usersLst = ref<any[]>([]);
        // 文件展示框是否显示，它显示，上传功能就隐藏
        const editData = reactive<any>({
            programCode: '',
            programName: '',
        });
        console.log(editData);
        const queryListData = ref<TableType[]>([]);
        const dataArray = ref<[]>([]);
        const dogetlistData = () => {
            const param = {
                bus: (props as any).buList.join(','),
                programCode: editData.programCode,
                programName: editData.programName,
            };
            getSICCOChecingDetailList(param).then((res) => {
                console.log(res);
                res.length > 0
                    ? (queryListData.value = res)
                    : (queryListData.value = []);
            });
            // const datalst = ref<any[]>([])
            // queryListData.value = datalst.value
            /*getCoverList({programCode: editData.programCode, programCategory: editData.offerTypeId}).then(res => {
              console.log(res.data)
              if (res.data.length>0){
                // dataArray.value = res.data
                const datalst = []
                for(const i of res.data){
                  datalst.push({username: i})
                }
                console.log(datalst)
                queryListData.value = datalst
              }
            })*/
            // queryListData.value = [{username:'aaa'},{username:'aaa'},{username:'aaa'},{username:'aaa'},{username:'aaa'},{username:'aaa'},{username:'aaa'},{username:'aaa'},{username:'aaa'},{username:'aaa'}]
        };
        dogetlistData();
        const dogetSelectData = () => {
            /*getCoverList({programCode: editData.programCode, programCategory: editData.offerTypeId}).then(res => {
                console.log(res.data)
                if (res.data.length>0){
                    // dataArray.value = res.data
                    const datalst = []
                    for(const i in res.data){
                        datalst.push({
                            user: res.data[i],
                            key:`${i}`
                        })
                    }
                    console.log(datalst)
                    usersLst.value = datalst
                }
            })*/
            usersLst.value = [
                {
                    user: 'SHICHENG',
                    key: '1',
                },
                {
                    user: 'TIANTAO',
                    key: '2',
                },
                {
                    user: 'JIAPENG',
                    key: '3',
                },
                {
                    user: 'XILIANG',
                    key: '4',
                },
                {
                    user: 'ZHONGYI',
                    key: '5',
                },
                {
                    user: 'ZHENGDA',
                    key: '6',
                },
                {
                    user: 'SICHENG',
                    key: '7',
                },
                {
                    user: 'XUDONG',
                    key: '8',
                },
                {
                    user: 'RONGRONG',
                    key: '9',
                },
            ];
        };
        dogetSelectData();
        //delete按钮
        const handleDelete = (rec: any) => {
            console.log(queryListData.value.length);
            const newArr = ref<any[]>([]);
            queryListData.value.forEach((item, index) => {
                if (item.username !== rec.username) {
                    newArr.value.push(item);
                }
            });
            queryListData.value = newArr.value;
        };
        const nowName = ref<any[]>([]);
        //add按钮
        const handleAdd = () => {
            let isrepeat = false;
            let isrepeadAll = false;
            const addArr = ref<any[]>([]);
            nowName.value.forEach((nameItem, nameIndex) => {
                queryListData.value.forEach((lstItem, lstIndex) => {
                    if (nameItem === lstItem.username) {
                        isrepeat = true;
                        return false;
                    }
                });
                if (!isrepeat) {
                    addArr.value.unshift({ username: nameItem });
                } else {
                    isrepeadAll = true;
                }
                isrepeat = false;
                // queryListData.value.unshift({username: nameItem})
            });
            if (!isrepeadAll) {
                const concatArr = addArr.value.concat(queryListData.value);
                queryListData.value = concatArr;
                console.log(concatArr);
                nowName.value = [];
            } else {
                message.error('Current assignee has already exists.');
            }
        };
        // // 设置抽屉显示状态
        const modal = (props as any).isSelectProgramCover;
        /*const handleSelect = (val: any) => {
            nowName.value = val
            console.log(val)
        }*/

        const close = (chooseData: any, programid: any, programName: any) => {
            if (chooseData !== null) {
                context.emit('close-sub-visible', {
                    isSelectProgramCover: false,
                    chooseData: chooseData,
                    programid: programid,
                    programName: programName,
                });
            } else {
                context.emit('close-sub-visible', {
                    isSelectProgramCover: false,
                    chooseData: '',
                });
            }
        };
        const handleSearch = (e: any) => {
            console.log(e, 'what is e: ');
            close(e.programCode, e.programId, e.programName);
        };

        // 确认编辑完成接口
        /*const upDateHandle = () => {
            // const pageDataStr = dataArray.join(",")

            const assArr = ref<any[]>([])
            let iscanconfirm = true
            queryListData.value.forEach((item, index)=>{
                assArr.value.forEach((assitem,assindex)=>{
                    if (assitem===item) {
                        message.error('AssignessArr duplicated.')
                        iscanconfirm = false
                    }else {
                        assArr.value.push(item)
                    }
                })
            })

            if (iscanconfirm) {
                let assignessStr = ''
                const assignessArr = ref<any[]>([])
                if (queryListData.value.length>0) {
                    queryListData.value.forEach((item,index) => {
                        assignessArr.value.push(item.username)
                    })
                    assignessStr = assignessArr.value.join(',')
                    const params = {
                        programCode:editData.programCode,
                        offerTypeId:editData.offerTypeId,
                        assignee:assignessStr
                    }
                    postCoverList(params).then(res => {
                        if (res.code === '0') {
                            message.success(res.message)
                            // context.emit('get-table-data')
                            close(true)
                        }
                    })
                }else {
                    message.error('At least choose one assignee.')
                }
            }


            /!*const params = editData
            docEdit(params).then(res => {
              if (res.code === '0') {
                message.success(res.message)
                context.emit('get-table-data')
                close()
              }
            })*!/
        }*/

        return {
            handleSearch,
            dogetSelectData,
            assignees,
            usersLst,
            nowName,
            handleAdd,
            dataArray,
            // handleSelect,
            dogetlistData,
            queryListData,
            handleDelete,
            columns,
            editData,
            modal,
            close,
            // upDateHandle,
        };
    },
});
