
import downLoadTemplate from '@/utils/checking/downloadFile2';
import { ExclamationCircleOutlined } from '@ant-design/icons-vue';

interface CheckingRoundType {
    key: string;
    value: string;
    text: string;
}
import { ref, onMounted, reactive, toRefs, defineComponent } from 'vue';
import { FileItem } from '@/API/types';
import { message } from 'ant-design-vue';
import {
    upImportCheckingResult,
    ImportCheckingResult,
} from '@/API/checking/SICCOCCheckingDocColl';

import axios from 'axios';
import downloadFileUrl from '@/utils/claim/downloadFileUrl';
export default defineComponent({
    emits: ['fresh-source-data'],
    components: {
        ExclamationCircleOutlined,
    },
    setup(prop, ctx) {
        const formData = new FormData();
        const visible = ref(false);
        const isImport = ref(true);
        //两个下拉框的值
        const isBeforeUpload = ref<boolean>(true);
        // 进度条
        const isShowUploadBtn = ref<boolean>(true);
        const isClickAbled = ref<boolean>(true);

        const curUploadFile = ref<object>({});
        const curPercent = ref<number>(0);
        const uploadFileSize = ref<string>('');
        const completeTips = ref<boolean>(false);
        const downloadTipsText = ref<any>('');
        const downloadTipsUrl = ref<any>('');
        const downloadModalVisible = ref<any>(false);
        const okButton = ref(false);

        const params = {
            offerTypeId: '',
            programId: '',
        };

        //关闭抽屉事件
        const closeThisDrawer = () => {
            visible.value = false;
        };
        const handleCancel = () => {
            downloadModalVisible.value = false;
        };
        //显示抽屉
        const showDrawer = (option: any) => {
            // params.offerTypeId = option.offerTypeId;
            // params.programId = option.programId;
            visible.value = true;
        };
        //重传
        const handelBrowse = () => {
            curPercent.value = 0;
            uploadFileSize.value = '';
            completeTips.value = false;
            isClickAbled.value = true;
            isShowUploadBtn.value = true;
            curUploadFile.value = {};
            isBeforeUpload.value = true;
            if (formData.has('file')) {
                formData.delete('file');
            }
        };

        //关闭抽屉的回调
        const afterVisibleChange = () => {
            isImport.value = true;
            curPercent.value = 0;
            uploadFileSize.value = '';
            completeTips.value = false;
            isClickAbled.value = true;
            isShowUploadBtn.value = true;
            curUploadFile.value = {};
            isBeforeUpload.value = true;
            if (formData.has('file')) {
                formData.delete('file');
            }
        };
        const loading = ref<boolean>(false);
        const loading2 = ref<boolean>(false);

        // 上传前
        const beforeUpload = (file: FileItem) => {
            const isXlsx =
                file.type ===
                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
            if (!isXlsx) {
                message.error('Only xlsx format files can be uploaded!');
            }
            const isLt5M = file.size / 1024 / 1024 < 5;
            if (!isLt5M) {
                message.error('The file size should not be larger than 5MB!');
            }
            return isXlsx && isLt5M;
        };

        const uploadRequest = (source: any) => {
            if (formData.has('file')) {
                formData.delete('file');
            }
            formData.append('file', source.file);
            curUploadFile.value = source.file;
            uploadFileSize.value =
                source.file.size / 1024 > 1024
                    ? `${(source.file.size / 1024 / 1024).toFixed(1)} MB`
                    : `${Math.floor(source.file.size / 1024)} KB`;
            isBeforeUpload.value = false;
        };
        // 上传功能出错后下载模态窗口
        const handleDownload = () => {
            loading2.value = true;
            const downloadParams = {
                // payoutRoundId: roundVal.value,

                path: downloadTipsUrl.value,
            };
            const params = {
                url: `/claimapi/common/download/errorFile`,
                method: 'get',
                params: downloadParams,
            };
            /*const paramsConfig = {
                url: downloadTipsUrl.value,
                method: "get",
                // fileName: `Export_Program_Enquiry_${timeStr}.xlsx`,
            };*/
            downloadFileUrl(params, 'application/vnd-excel; char-set=UTF-8').then(
                (res) => {
                    loading2.value = false;
                    downloadModalVisible.value = false;

                    // emptyVisible.value = false;
                }
            );
        };

        // 上传文件
        const uploadFile = (source: any) => {
            isClickAbled.value = false;
            upImportCheckingResult(formData)
                .then((res: any) => {
                    console.log(res);
                    if (res.code === '0') {
                        message.success('Successfully uploaded');
                        isImport.value = false;
                    } else if (res.code === '50056') {
                        console.log(res.data);
                        downloadModalVisible.value = true;
                        okButton.value = false;
                        downloadTipsText.value =
                            'Data validation unsuccessful, please check and reupload.';
                        downloadTipsUrl.value = res.data;
                    } else {
                        message.error(res.message);
                    }
                    isShowUploadBtn.value = false;
                    curPercent.value = 100;
                    completeTips.value = true;
                    isClickAbled.value = true;
                })
                .catch(() => {
                    isShowUploadBtn.value = false;
                });
        };

        //ok按钮，刷新表格
        const freshTable = () => {
            /*const param = {
                progDesignId:params.programId,
                offerTypeId:params.offerTypeId,
            }
            ImportCheckingResult(param,formData).then((res: any) => {
                ctx.emit("fresh-source-data")
                visible.value = false
            })*/
            ctx.emit('fresh-source-data');
            visible.value = false;
        };
        return {
            loading2,
            handleDownload,
            handleCancel,
            okButton,
            downloadModalVisible,
            downloadTipsUrl,
            downloadTipsText,
            uploadFile,
            loading,
            handelBrowse,
            freshTable,
            isShowUploadBtn,
            curUploadFile,
            curPercent,
            uploadFileSize,
            completeTips,
            isClickAbled,
            isBeforeUpload,
            beforeUpload,
            uploadRequest,
            visible,
            afterVisibleChange,
            isImport,
            closeThisDrawer,
            showDrawer,
        };
    },
});
